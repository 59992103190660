import Vue from "vue";
const vm = Vue.prototype;
import i18n from "../../plugins/i18n";

export default {
  state: {
    newBlogsIsLoading: false, // yeni ürün loadingi
    blogListRequested: false, // daha önce ürün verisi çekilmiş mi?
    blogsAreLoading: false, // ürünler listesi loadingi
    blogs: [], // ürünler listesi
    count: 0, // kaç adet ürün bulunuyor bilgisi.
  },
  mutations: {
    // ürünlerin loadingi
    SET_BLOGS_ARE_LOADING(state, payload) {
      state.blogsAreLoading = payload;
    },
    // ürünleri projeye ekle
    SET_BLOGS(state, payload) {
      if (!state.blogListRequested) state.blogs = [];
      if (payload.search !== "" || payload.categoryId !== -1) {
        state.blogs = [];
        state.blogListRequested = false;
      } else state.blogListRequested = true;

      state.blogs = [...state.blogs, ...payload.data];
      state.count = payload.count;
    },
    // ürünü yeni eklerkenki loading
    SET_NEW_BLOG_IS_LOADING(state, payload) {
      state.newBlogsIsLoading = payload;
    },
    // eklenen ürünü ekle
    SET_NEW_BLOG_TO_LIST(state, payload) {
      state.blogs.unshift(payload);
    },
    // güncellenen ürünü ekle
    SET_UPDATE_BLOG_TO_LIST(state, payload) {
      const blogIndex = state.blogs.findIndex((c) => c.id === payload.id);
      if (blogIndex !== -1)
        state.blogs[blogIndex] = { ...state.blogs[blogIndex], ...payload };
    },
    // ürünü sil
    SET_REMOVE_BLOG(state, { id }) {
      const blogIndex = state.blogs.findIndex((c) => c.id === id);
      if (blogIndex !== -1) state.blogs.splice(blogIndex, 1);
    },
  },
  actions: {
    // ürünleri getir
    async GET_BLOGS(
      { state, commit },
      { page = 1, search = "", limit = 12, type, categoryId = -1 }
    ) {
      if (
        state.blogs.length !== 0 &&
        (search === "") & state.blogListRequested &&
        state.blogs.map((blog) => blog.page).includes(page) &&
        categoryId === -1
      )
        return; // daha önce liste çekilmişse return at.
      commit("SET_BLOGS_ARE_LOADING", true);
      const response = await vm.$api.get(
        `Blogs?page=${page}&searchKey=${search}&limit=${limit}${
          categoryId === -1 ? "" : `&categoryId=${categoryId}`
        }`
      );
      if (response.message === "OK" && response.result === "OK")
        commit("SET_BLOGS", {
          count: response.data.count || 0,
          search,
          categoryId,
          data: response.data.items.map((d) => {
            // .items gelecek.
            return {
              ...d,
              page,
              type,
            };
          }),
        });
      else commit("SET_BLOGS", []);
      commit("SET_BLOGS_ARE_LOADING", false);
    },
    // yeni ürünü kayıt etme
    async SEND_NEW_BLOG({ commit }, { fd, form }) {
      commit("SET_NEW_BLOG_IS_LOADING", true);
      commit("SET_BLOGS_ARE_LOADING", true);
      const response = await vm.$api.post(`Blogs`, fd);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_NEW_BLOG_TO_LIST", { ...form, id: response.data });
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("blogHasbeenSucceed"),
          },
        });
        commit("SET_NEW_BLOG_IS_LOADING", false);
        commit("SET_BLOGS_ARE_LOADING", false);
        commit("CLEAR_CATEGORIES");
        return response.data;
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("blogHasnotBeenSucceed"),
          },
        });
        commit("SET_NEW_BLOG_IS_LOADING", false);
        commit("SET_BLOGS_ARE_LOADING", false);
        return false;
      }
    },
    // ürünü güncelle
    async SEND_UPDATE_BLOG({ commit }, { fd, form }) {
      commit("SET_NEW_BLOG_IS_LOADING", true); // store blogs.js
      commit("SET_BLOGS_ARE_LOADING", true); // store blogs.js
      const response = await vm.$api.put(`Blogs/${form.id}`, fd);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_UPDATE_BLOG_TO_LIST", { ...form }); // store blogs.js
        commit("SET_UPDATE_PRODUCT", { ...form }); // store products.js
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("blogHasbeenUpdated"),
          },
        });
        commit("SET_NEW_BLOG_IS_LOADING", false);
        commit("SET_BLOGS_ARE_LOADING", false);

        return form.id;
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("blogHasNotBeenUpdated"),
          },
        });
        commit("SET_NEW_BLOG_IS_LOADING", false);
        commit("SET_BLOGS_ARE_LOADING", false);
        return false;
      }
    },
    async SEND_USER_TO_BLOG(_, payload) {
      const response = await vm.$api.post("Products?table=blog", payload);
      if (response.message === "OK") {
        return {
          status: true,
          variant: "success",
          message: i18n.t("productHasBeenInsertedToUser"),
        };
      } else if (response.message === "PRODUCT ALREADY EXISTS") {
        return {
          status: true,
          variant: "danger",
          message: i18n.t("productAlreadyExist"),
        };
      } else {
        return {
          status: true,
          variant: "danger",
          message: i18n.t("productHasNotBeenInsertedToUser"),
        };
      }
    },
  },
};
