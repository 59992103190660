import Vue from "vue";
const vm = Vue.prototype;
export default {
  state: {
    paymentMethods: [],
    paymentMethodsIsLoading: false,
  },
  mutations: {
    SET_PAYMENT_METHODS(state, payload) {
      state.paymentMethods = payload;
    },
    SET_PAYMENT_METHODS_IS_LOADING(state, payload) {
      state.paymentMethodsIsLoading = payload;
    },
    SET_NEW_PAYMENT_METHODS(state, payload) {
      state.paymentMethods.push(payload);
    },
    SET_UPDATE_PAYMENT_METHODS(state, payload) {
      const index = state.paymentMethods.findIndex(
        (pm) => pm.id === payload.id
      );
      if (index !== -1) {
        state.paymentMethods[index] = {
          ...state.paymentMethods[index],
          ...payload,
        };
      }
    },
  },
  actions: {
    async GET_PAYMENT_METHODS({ commit }) {
      commit("SET_PAYMENT_METHODS_IS_LOADING", true);
      const response = await vm.$api.get("PaymentMethods");
      if (response.message === "OK") {
        commit("SET_PAYMENT_METHODS", response.data);
        commit("SET_PAYMENT_METHODS_IS_LOADING", false);
      } else {
        commit("SET_PAYMENT_METHODS", []);
        commit("SET_PAYMENT_METHODS_IS_LOADING", false);
      }
    },
    async SEND_NEW_PAYMENT_METHODS_TRANSFER({ commit }, payload) {
      const response = await vm.$api.post("PaymentMethods/Transfer", payload);
      if (response.message === "OK") {
        commit("SET_NEW_PAYMENT_METHODS", {
          ...payload,
          id: response.data,
          type: 3,
        });
        return true;
      }
      return false;
    },
    async SEND_UPDATE_PAYMENT_METHODS_TRANSFER({ commit }, payload) {
      const response = await vm.$api.put(
        `PaymentMethods/Transfer/${payload.id}`,
        payload
      );
      if (response.message === "OK") {
        commit("SET_UPDATE_PAYMENT_METHODS", payload);
        return true;
      }
      return false;
    },
    async SEND_UPDATE_PAYMENT_METHODS_IYZICO({ commit }, payload) {
      const response = await vm.$api.put(
        `PaymentMethods/Iyzico/${payload.id}`,
        payload
      );
      if (response.message === "OK") {
        commit("SET_UPDATE_PAYMENT_METHODS", payload);
        return true;
      }
      return false;
    },
    async SEND_UPDATE_PAYMENT_METHODS_PAYPAL({ commit }, payload) {
      const response = await vm.$api.put(
        `PaymentMethods/Paypal/${payload.id}`,
        payload
      );
      if (response.message === "OK") {
        commit("SET_UPDATE_PAYMENT_METHODS", payload);
        return true;
      }
      return false;
    },
    async SEND_NEW_PAYMENT_METHODS_IYZICO({ commit }, payload) {
      const response = await vm.$api.post("PaymentMethods/Iyzico", payload);
      if (response.message === "OK") {
        commit("SET_NEW_PAYMENT_METHODS", {
          key: payload.apiKey,
          secret: payload.secretKey,
          id: response.data,
          serviceType: payload.serviceType,
          merchantId: payload.merchantId,
          baseUrl: payload.baseUrl,
          type: 1,
        });
        return true;
      }
      return false;
    },
    async SEND_NEW_PAYMENT_METHODS_PAYPAL({ commit }, payload) {
      const response = await vm.$api.post("PaymentMethods/Paypal", payload);
      if (response.message === "OK") {
        commit("SET_NEW_PAYMENT_METHODS", {
          key: payload.clientId,
          secret: payload.clientSecret,
          id: response.data,
          type: 2,
        });
        return true;
      }
      return false;
    },
  },
};
