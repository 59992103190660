import VueI18n from "vue-i18n";
import Vue from "vue";
// Create VueI18n instance with options
import tr from "../locales/tr.json";
import de from "../locales/de.json";
import en from "../locales/en.json";
import es from "../locales/es.json";
Vue.use(VueI18n);
const messages = {
  tr,
  de,
  en,
  es
};

const i18n = new VueI18n({
  locale: "en", // set locale
  messages, // set locale messages
});


export default i18n;
