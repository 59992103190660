import Vue from "vue";
const vm = Vue.prototype;
import CryptoJS from "gurhan/helpers/Crypto";
const crypto = new CryptoJS("MAKETODAYAMAZING");
export default {
  state: {
    company: {},
    user: {},
    name: "",
    expiredAt: 0,
    domain: "",
    connected: false,
    rooms: [],
    activeRoom: { Id: -1 },
    roomsAreLoading: true,
  },
  mutations: {
    SET_REZZERVATION_INFO_FROM_EXTERNAL(state, payload) {
      state.company = payload.company;
      state.user = payload.user;
      state.name = payload.name;
      state.expiredAt = payload.expiredAt;
      state.domain = payload.domain;
      state.connected = true;
      localStorage.setItem("rez_info", crypto.crypto(JSON.stringify(payload)));
    },
    LOGOUT_REZERVATION(state) {
      state.company = {};
      state.user = {};
      state.name = "";
      state.expiredAt = 0;
      state.domain = "";
      state.connected = false;
      localStorage.removeItem("rez_info");
    },
    SET_ROOMS_FOR_REZERVATION(state, payload) {
      state.rooms = payload;
      state.activeRoom = payload[0];
    },
    SET_ACTIVE_ROOM_FOR_REZERVATION(state, payload) {
      state.activeRoom = payload;
    },
    SET_ROOMS_ARE_LOADING(state, payload) {
      state.roomsAreLoading = payload;
    },
  },
  actions: {
    async GET_REZZERVATION_INFO_AND_ROOM({ commit }, payload) {
      const today = new Date().getTime();
      if (today < payload.expiredAt) {
        vm.$apiForRez.token = payload.company.CompanyToken;
        commit("SET_REZZERVATION_INFO_FROM_EXTERNAL", payload);
        commit("SET_ROOMS_ARE_LOADING", true);
        const response = await vm.$apiForRez.get("Rooms/GetAll");
        console.log(response);
        if (response.message === "OK" && response.result === "OK") {
          if (response.data.length !== 0)
            commit("SET_ROOMS_FOR_REZERVATION", response.data);
        } else if (response.result === "FAIL") {
          commit("LOGOUT_REZERVATION");
        }
        commit("SET_ROOMS_ARE_LOADING", false);
      }
    },
  },
};
