export default {
  state: {
    settings: {
      isCourses: 0,
      isEvents: 1,
      isBooks: 1,
      isBlog: 1,
      isSeances: 0,
      logo: "http://localhost:8080/images/logo.png",
      title: "Heal and More",
      phone: "+90 534 726 44 86",
      location: "Güzelbahçe",
      email: "hello@healandmore.com",
      twitter: "https://twitter.com/healandmore",
      facebook: "https://www.facebook.com/healandmore",
      instagram: "https://www.instagram.com/healandmore/?hl=en",
      linkedin: "https://www.linkedin.com/in/sinan-özcan-msc/",
      youtube: "https://www.youtube.com/healandmore",
    },
  },
  mutations: {},
  actions: {},
};
