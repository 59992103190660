import Vue from "vue";
const vm = Vue.prototype;
import i18n from "../../plugins/i18n";

export default {
  state: {
    categories: [], // list of categories
    categoriesAreLoading: true, // loading which is getting categories
  },
  mutations: {
    // for set all categories on app.
    SET_CATEGORY(state, payload) {
      state.categories = payload;
    },
    // for set loading when getting categories.
    SET_CATEGORY_ARE_LOADING(state, payload) {
      state.categoriesAreLoading = payload;
    },
    // for set category which is updated.
    SET_TO_UPDATE_CATEGORY(state, payload) {
      const index = state.categories.findIndex((c) => c.id === payload.id);
      if (index !== -1) state.categories[index] = payload;
    },
    // for set category which is added
    SET_TO_ADD_CATEGORY(state, payload) {
      state.categories.push(payload);
    },
    // for remove category which is removed
    SET_REMOVE_CATEGORY_WHICH_IS_REMOVED(state, payload) {
      const payloadIndex = state.categories.findIndex((c) => c.id === payload);
      if (payloadIndex !== -1) state.categories.splice(payloadIndex, 1);
    },
    CLEAR_CATEGORIES(state) {
      state.categories = [];
    },
  },
  actions: {
    // get list of categories.
    async GET_CATEGORIES({ state, commit }) {
      if (state.categories.length !== 0) return; // daha önce liste çekilmişse return at.
      commit("SET_CATEGORY_ARE_LOADING", true);
      const response = await vm.$api.get("Categories");
      if (response.message === "OK" && response.result === "OK")
        commit("SET_CATEGORY", response.data.items);
      else commit("SET_CATEGORY", []);
      commit("SET_CATEGORY_ARE_LOADING", false);
    },
    // for update of category.
    async SEND_TO_UPDATE_CATEGORY({ commit }, { fd, category }) {
      const response = await vm.$api.put(`Categories/${category.id}`, fd);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_TO_UPDATE_CATEGORY", category);
        return {
          status: true,
          variant: "success",
          message: i18n.t("updatedIsSucceed"),
        };
      } else {
        return {
          status: true,
          variant: "danger",
          message: i18n.t("updatedIsNotSucceed"),
        };
      }
    },
    // for add category
    async SEND_TO_ADD_CATEGORY({ commit }, { fd, form }) {
      const response = await vm.$api.post(`Categories`, fd);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_TO_ADD_CATEGORY", {
          ...form,
          id: response.data,
          imagePath: "/Images/default.png",
        });
        return {
          status: true,
          variant: "success",
          message: i18n.t("categoryHasBeenInserted"),
          id: response.data,
        };
      } else {
        return {
          status: true,
          variant: "danger",
          message: i18n.t("categoryHasNotBeenInserted"),
        };
      }
    },
  },
};
