import Vue from "vue";
const vm = Vue.prototype;
import i18n from "../../plugins/i18n";

export default {
  state: {
    newBooksIsLoading: false, // yeni ürün loadingi
    bookListRequested: false, // daha önce ürün verisi çekilmiş mi?
    booksAreLoading: false, // ürünler listesi loadingi
    books: [], // ürünler listesi
    count: 0, // kaç adet ürün bulunuyor bilgisi.
  },
  mutations: {
    // ürünlerin loadingi
    SET_BOOKS_ARE_LOADING(state, payload) {
      state.booksAreLoading = payload;
    },
    // ürünleri projeye ekle
    SET_BOOKS(state, payload) {
      if (!state.bookListRequested) state.books = [];
      if (payload.search !== "" || payload.categoryId !== -1) {
        state.books = [];
        state.bookListRequested = false;
      } else state.bookListRequested = true;

      state.books = [...state.books, ...payload.data];
      state.count = payload.count;
    },
    // ürünü yeni eklerkenki loading
    SET_NEW_BOOK_IS_LOADING(state, payload) {
      state.newBooksIsLoading = payload;
    },
    // eklenen ürünü ekle
    SET_NEW_BOOK_TO_LIST(state, payload) {
      state.books.unshift(payload);
    },
    // güncellenen ürünü ekle
    SET_UPDATE_BOOK_TO_LIST(state, payload) {
      const bookIndex = state.books.findIndex((c) => c.id === payload.id);
      if (bookIndex !== -1)
        state.books[bookIndex] = { ...state.books[bookIndex], ...payload };
    },
    // ürünü sil
    SET_REMOVE_BOOK(state, { id }) {
      const bookIndex = state.books.findIndex((c) => c.id === id);
      if (bookIndex !== -1) state.books.splice(bookIndex, 1);
    },
  },
  actions: {
    // ürünleri getir
    async GET_BOOKS(
      { state, commit },
      { page = 1, search = "", limit = 12, type, categoryId = -1 }
    ) {
      if (
        state.books.length !== 0 &&
        (search === "") & state.bookListRequested &&
        state.books.map((book) => book.page).includes(page) &&
        categoryId === -1
      )
        return state.books; // daha önce liste çekilmişse return at.
      commit("SET_BOOKS_ARE_LOADING", true);
      const response = await vm.$api.get(
        `Books?page=${page}&searchKey=${search}&limit=${limit}${
          categoryId === -1 ? "" : `&categoryId=${categoryId}`
        }`
      );
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_BOOKS", {
          count: response.data.count || 0,
          search,
          categoryId,
          data: response.data.items.map((d) => {
            // .items gelecek.
            return {
              ...d,
              page,
              type,
            };
          }),
        });
        commit("SET_BOOKS_ARE_LOADING", false);
        return response.data;
      } else {
        commit("SET_BOOKS", []);
        commit("SET_BOOKS_ARE_LOADING", false);
        return [];
      }
    },
    // yeni ürünü kayıt etme
    async SEND_NEW_BOOK({ commit }, { fd, form }) {
      commit("SET_NEW_BOOK_IS_LOADING", true);
      commit("SET_BOOKS_ARE_LOADING", true);
      const response = await vm.$api.post(`Books`, fd);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_NEW_BOOK_TO_LIST", { ...form, id: response.data });
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("bookHasbeenSucceed"),
          },
        });
        commit("SET_NEW_BOOK_IS_LOADING", false);
        commit("SET_BOOKS_ARE_LOADING", false);
        commit("CLEAR_CATEGORIES");
        return response.data;
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("bookHasnotBeenSucceed"),
          },
        });
        commit("SET_NEW_BOOK_IS_LOADING", false);
        commit("SET_BOOKS_ARE_LOADING", false);
        return false;
      }
    },
    // ürünü güncelle
    async SEND_UPDATE_BOOK({ commit }, { fd, form }) {
      commit("SET_NEW_BOOK_IS_LOADING", true); // store books.js
      commit("SET_BOOKS_ARE_LOADING", true); // store books.js
      const response = await vm.$api.put(`Books/${form.id}`, fd);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_UPDATE_BOOK_TO_LIST", { ...form }); // store books.js
        commit("SET_UPDATE_PRODUCT", { ...form }); // store products.js
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("bookHasbeenUpdated"),
          },
        });
        commit("SET_NEW_BOOK_IS_LOADING", false);
        commit("SET_BOOKS_ARE_LOADING", false);

        return form.id;
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("bookHasNotBeenUpdated"),
          },
        });
        commit("SET_NEW_BOOK_IS_LOADING", false);
        commit("SET_BOOKS_ARE_LOADING", false);
        return false;
      }
    },
    async SEND_USER_TO_BOOK(_, payload) {
      const response = await vm.$api.post("Products?table=book", payload);
      if (response.message === "OK") {
        return {
          status: true,
          variant: "success",
          message: i18n.t("productHasBeenInsertedToUser"),
        };
      } else if (response.message === "PRODUCT ALREADY EXISTS") {
        return {
          status: true,
          variant: "danger",
          message: i18n.t("productAlreadyExist"),
        };
      } else {
        return {
          status: true,
          variant: "danger",
          message: i18n.t("productHasNotBeenInsertedToUser"),
        };
      }
    },
  },
};
