import Vue from "vue";
const vm = Vue.prototype;
import i18n from "../../plugins/i18n";

export default {
  state: {
    newCouponsIsLoading: false, // yeni ürün loadingi
    couponsListRequested: false, // daha önce ürün verisi çekilmiş mi?
    CouponsAreLoading: false, // ürünler listesi loadingi
    Coupons: [], // ürünler listesi
    count: 0, // kaç adet ürün bulunuyor bilgisi.
  },
  mutations: {
    // ürünlerin loadingi
    SET_COUPONS_ARE_LOADING(state, payload) {
      state.CouponsAreLoading = payload;
    },
    // ürünleri projeye ekle
    SET_COUPONS(state, payload) {
      if (!state.couponsListRequested) state.Coupons = [];
      if (payload.search !== "") {
        state.Coupons = [];
        state.couponsListRequested = false;
      } else state.couponsListRequested = true;

      state.Coupons = [...state.Coupons, ...payload.data];
      state.count = payload.count;
    },
    // ürünü yeni eklerkenki loading
    SET_NEW_COUPON_IS_LOADING(state, payload) {
      state.newCouponsIsLoading = payload;
    },
    // eklenen ürünü ekle
    SET_NEW_COUPON_TO_LIST(state, payload) {
      state.Coupons.unshift(payload);
    },
    // güncellenen ürünü ekle
    SET_UPDATE_COUPON_TO_LIST(state, payload) {
      const couponsIndex = state.Coupons.findIndex((c) => c.id === payload.id);
      if (couponsIndex !== -1)
        state.Coupons[couponsIndex] = {
          ...state.Coupons[couponsIndex],
          ...payload,
        };
    },
    // ürünü sil
    SET_REMOVE_COUPON(state, { id }) {
      const couponsIndex = state.Coupons.findIndex((c) => c.id === id);
      if (couponsIndex !== -1) state.Coupons[couponsIndex].isActive = 0;
    },
  },
  actions: {
    // ürünleri getir
    async GET_COUPONS(
      { state, commit },
      { page = 1, search = "", limit = 12 }
    ) {
      console.log(page);
      if (
        state.Coupons.length !== 0 &&
        (search === "") & state.couponsListRequested &&
        state.Coupons.map((coupons) => coupons.page).includes(page)
      )
        return; // daha önce liste çekilmişse return at.
      commit("SET_COUPONS_ARE_LOADING", true);
      const response = await vm.$api.get(
        `PromotionCodes?page=${page}&searchKey=${search}&limit=${limit}`
      );
      if (response.message === "OK" && response.result === "OK")
        commit("SET_COUPONS", {
          count: response.data.count || 0,
          search,
          data: response.data.items.map((d) => {
            // .items gelecek.
            return {
              ...d,
              page,
            };
          }),
        });
      else commit("SET_COUPONS", []);
      commit("SET_COUPONS_ARE_LOADING", false);
    },
    // yeni ürünü kayıt etme
    async SEND_NEW_COUPON({ commit }, form) {
      commit("SET_NEW_COUPON_IS_LOADING", true);
      commit("SET_COUPONS_ARE_LOADING", true);
      const response = await vm.$api.post(`PromotionCodes`, form);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_NEW_COUPON_TO_LIST", {
          ...form,
          isActive: 1,
          id: response.data,
        });
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("insertIsSucceed"),
          },
        });
        commit("SET_NEW_COUPON_IS_LOADING", false);
        commit("SET_COUPONS_ARE_LOADING", false);
        return response.data;
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("insertIsNotSucceed"),
          },
        });
        commit("SET_NEW_COUPON_IS_LOADING", false);
        commit("SET_COUPONS_ARE_LOADING", false);
        return false;
      }
    },
    // ürünü güncelle
    async SEND_UPDATE_COUPON({ commit }, form) {
      commit("SET_NEW_COUPON_IS_LOADING", true); // store Coupons.js
      commit("SET_COUPONS_ARE_LOADING", true); // store Coupons.js
      const response = await vm.$api.put(`PromotionCodes/${form.id}`, form);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_UPDATE_COUPON_TO_LIST", { ...form }); // store Coupons.js
        commit("SET_UPDATE_PRODUCT", { ...form }); // store products.js
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("updatedIsSucceed"),
          },
        });
        commit("SET_NEW_COUPON_IS_LOADING", false);
        commit("SET_COUPONS_ARE_LOADING", false);

        return form.id;
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("updatedIsNotSucceed"),
          },
        });
        commit("SET_NEW_COUPON_IS_LOADING", false);
        commit("SET_COUPONS_ARE_LOADING", false);
        return false;
      }
    },
    // ürüne öğrenci ekle
    async SEND_COUPON_FOR_VALID_COUPON(_, payload) {
      const response = await vm.$api.post(
        `PromotionCodes?code=${payload.code}&currency=${payload.currency}`
      );
      if (response.message === "OK") {
        return {
          status: true,
          variant: "success",
          message: i18n.t("promotionCodeApplied"),
        };
      } else {
        return {
          status: true,
          variant: "danger",
          message: i18n.t("promotionCodeInvalid"),
        };
      }
    },
  },
};
