import Vue from "vue";
const vm = Vue.prototype;
import i18n from "../../plugins/i18n";

export default {
  state: {
    newCourseIsLoading: false, // yeni ürün loadingi
    courseListRequested: false, // daha önce ürün verisi çekilmiş mi?
    coursesAreLoading: false, // ürünler listesi loadingi
    courses: [], // ürünler listesi
    count: 0, // kaç adet ürün bulunuyor bilgisi.
  },
  mutations: {
    // ürünlerin loadingi
    SET_COURSES_ARE_LOADING(state, payload) {
      state.coursesAreLoading = payload;
    },
    // ürünleri projeye ekle
    SET_COURSES(state, payload) {
      if (!state.courseListRequested) state.courses = [];
      if (payload.search !== "" || payload.categoryId !== -1) {
        state.courses = [];
        state.courseListRequested = false;
      } else state.courseListRequested = true;
      state.courses = [...state.courses, ...payload.data];
      state.count = payload.count;
    },
    // ürünü yeni eklerkenki loading
    SET_NEW_COURSE_IS_LOADING(state, payload) {
      state.newCourseIsLoading = payload;
    },
    // eklenen ürünü ekle
    SET_NEW_COURSE_TO_LIST(state, payload) {
      state.courses.unshift(payload);
    },
    // güncellenen ürünü ekle
    SET_UPDATE_COURSE_TO_LIST(state, payload) {
      const courseIndex = state.courses.findIndex((c) => c.id === payload.id);
      if (courseIndex !== -1)
        state.courses[courseIndex] = {
          ...state.courses[courseIndex],
          ...payload,
        };
    },
    // ürünü sil
    SET_REMOVE_COURSE(state, { id }) {
      const courseIndex = state.courses.findIndex((c) => c.id === id);
      if (courseIndex !== -1) state.courses.splice(courseIndex, 1);
    },
  },
  actions: {
    // ürünleri getir
    async GET_COURSES(
      { state, commit },
      { page = 1, search = "", limit = 12, type, categoryId = -1 }
    ) {
      if (
        state.courses.length !== 0 &&
        (search === "") & state.courseListRequested &&
        state.courses.map((course) => course.page).includes(page) &&
        categoryId === -1
      )
        return state.courses; // daha önce liste çekilmişse return at.
      commit("SET_COURSES_ARE_LOADING", true);
      const response = await vm.$api.get(
        `Courses?page=${page}&searchKey=${search}&limit=${limit}${
          categoryId === -1 ? "" : `&categoryId=${categoryId}`
        }`
      );
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_COURSES", {
          count: response.data.count || 0,
          search,
          categoryId,
          data: response.data.items.map((d) => {
            // .items gelecek.
            return {
              ...d,
              page,
              type,
            };
          }),
        });
        commit("SET_COURSES_ARE_LOADING", false);
        return response.data;
      } else {
        commit("SET_COURSES", []);
        commit("SET_COURSES_ARE_LOADING", false);
        return [];
      }
    },
    // yeni ürünü kayıt etme
    async SEND_NEW_COURSE({ commit }, { fd, form }) {
      commit("SET_NEW_COURSE_IS_LOADING", true);
      commit("SET_COURSES_ARE_LOADING", true);
      const response = await vm.$api.post(`Courses`, fd);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_NEW_COURSE_TO_LIST", { ...form, id: response.data });
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("insertIsSucceed"),
          },
        });
        commit("SET_NEW_COURSE_IS_LOADING", false);
        commit("SET_COURSES_ARE_LOADING", false);
        commit("CLEAR_CATEGORIES");
        return response.data;
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("insertIsNotSucceed"),
          },
        });
        commit("SET_NEW_COURSE_IS_LOADING", false);
        commit("SET_COURSES_ARE_LOADING", false);
        return false;
      }
    },
    // ürünü güncelle
    async SEND_UPDATE_COURSE({ commit }, { fd, form }) {
      console.log(form, "sen d update course");
      commit("SET_NEW_COURSE_IS_LOADING", true); // store courses.js
      commit("SET_COURSES_ARE_LOADING", true); // store courses.js
      const response = await vm.$api.put(`Courses/${form.id}`, fd);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_UPDATE_COURSE_TO_LIST", { ...form }); // store courses.js
        commit("SET_UPDATE_PRODUCT", { ...form }); // store products.js
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("updatedIsSucceed"),
          },
        });
        commit("SET_NEW_COURSE_IS_LOADING", false);
        commit("SET_COURSES_ARE_LOADING", false);

        return form.id;
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("updatedIsNotSucceed"),
          },
        });
        commit("SET_NEW_COURSE_IS_LOADING", false);
        commit("SET_COURSES_ARE_LOADING", false);
        return false;
      }
    },
    // ürüne öğrenci ekle
    async SEND_USER_TO_COURSE(_, payload) {
      const response = await vm.$api.post("Products?table=course", payload);
      if (response.message === "OK") {
        return {
          status: true,
          variant: "success",
          message: i18n.t("productHasBeenInsertedToUser"),
        };
      } else if (response.message === "PRODUCT ALREADY EXISTS") {
        return {
          status: true,
          variant: "danger",
          message: i18n.t("productAlreadyExist"),
        };
      } else {
        return {
          status: true,
          variant: "danger",
          message: i18n.t("productHasNotBeenInsertedToUser"),
        };
      }
    },
  },
};
