import Vue from "vue";
const vm = Vue.prototype;
import i18n from "../../plugins/i18n";

export default {
  state: {
    eventItems: [], // event saatleri
    eventItemsAreLoading: false, // event saatleri yükleniyor...

    newEventsIsLoading: false, // yeni ürün loadingi
    eventListRequested: false, // daha önce ürün verisi çekilmiş mi?
    eventsAreLoading: false, // ürünler listesi loadingi
    events: [], // ürünler listesi
    count: 0, // kaç adet ürün bulunuyor bilgisi.
  },
  mutations: {
    //event items loading
    SET_EVENT_ITEMS_ARE_LOADING(state, payload) {
      state.eventItemsAreLoading = payload;
    },
    // event itemleri set et
    SET_EVENT_ITEMS(state, payload) {
      console.log(payload, "set event items");
      state.eventItems.push(payload);
    },
    // event itemi güncelle
    SET_TO_UPDATE_EVENT_ITEM(state, payload) {
      state.eventItemsAreLoading = true;
      const productIndex = state.eventItems.findIndex(
        (e) => e.eventId === payload.productId
      );
      if (productIndex === -1) return;
      const index = state.eventItems[productIndex].data.findIndex(
        (e) => e.id === payload.id
      );
      if (index === -1) return;
      state.eventItems[productIndex].data[index] = { ...payload };
      state.eventItemsAreLoading = false;
    },
    // event itemi ekle
    SET_TO_NEW_EVENT_ITEM(state, payload) {
      state.eventItemsAreLoading = true;
      const productIndex = state.eventItems.findIndex(
        (e) => e.eventId === payload.productId
      );
      if (productIndex === -1) return;
      state.eventItems[productIndex].data.unshift(payload);
    },
    SET_TO_REMOVE_EVENT_ITEM(state, payload) {
      const productIndex = state.eventItems.findIndex(
        (e) => e.eventId === payload.productId
      );
      if (productIndex === -1) return;
      const index = state.eventItems[productIndex].data.findIndex(
        (e) => e.id === payload.id
      );
      if (index === -1) return;
      state.eventItems[productIndex].data[index].isActive = 0;
    },

    // ürünlerin loadingi
    SET_EVENTS_ARE_LOADING(state, payload) {
      state.eventsAreLoading = payload;
    },
    // ürünleri projeye ekle
    SET_EVENTS(state, payload) {
      if (!state.eventListRequested) state.events = [];
      if (payload.search !== "" || payload.categoryId !== -1) {
        state.events = [];
        state.eventListRequested = false;
      } else state.eventListRequested = true;

      state.events = [...state.events, ...payload.data];
      state.count = payload.count;
    },
    // ürünü yeni eklerkenki loading
    SET_NEW_EVENT_IS_LOADING(state, payload) {
      state.newEventsIsLoading = payload;
    },
    // eklenen ürünü ekle
    SET_NEW_EVENT_TO_LIST(state, payload) {
      state.events.unshift(payload);
    },
    // güncellenen ürünü ekle
    SET_UPDATE_EVENT_TO_LIST(state, payload) {
      const eventIndex = state.events.findIndex((c) => c.id === payload.id);
      if (eventIndex !== -1)
        state.events[eventIndex] = { ...state.events[eventIndex], ...payload };
    },
    // ürünü sil
    SET_REMOVE_EVENT(state, { id }) {
      const eventIndex = state.events.findIndex((c) => c.id === id);
      if (eventIndex !== -1) state.events.splice(eventIndex, 1);
    },
  },
  actions: {
    // event items listesini getir.
    async GET_EVENT_ITEMS({ state, commit }, { page = 1, limit = 12, id }) {
      if (state.eventItems.find((c) => c.id === id && c.page === page)) return; // daha önce liste çekilmişse return at.
      commit("SET_EVENT_ITEMS_ARE_LOADING", true);
      const response = await vm.$api.get(
        `EventItems?eventId=${id}&page=${page}&limit=${limit}`
      );
      if (response.message === "OK" && response.result === "OK")
        commit("SET_EVENT_ITEMS", {
          count: response.data.count || 0,
          eventId: id,
          page,
          data: response.data.map((d) => {
            // .items gelecek.
            return {
              ...d,
              page,
            };
          }),
        });
      commit("SET_EVENT_ITEMS_ARE_LOADING", false);
    },
    // event item'i güncelle
    async SEND_TO_UPDATE_EVENT_ITEM({ commit }, payload) {
      const response = await vm.$api.put(`EventItems/${payload.id}`, {
        date: payload.date,
        time: payload.time,
        duration: payload.duration,
        hiddenInfo: payload.hiddenInfo,
        expiredAt: payload.expiredAt,
      });
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_TO_UPDATE_EVENT_ITEM", payload);
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("updatedIsSucceed"),
          },
        });
        return true;
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("updatedIsNotSucceed"),
          },
        });
        return false;
      }
    },
    // event item ekle
    async SEND_TO_NEW_EVENT_ITEM({ commit }, payload) {
      const response = await vm.$api.post(`EventItems`, {
        date: payload.date,
        time: payload.time,
        duration: payload.duration,
        expiredAt: payload.expiredAt,
        eventId: payload.productId,
        hiddenInfo: payload.hiddenInfo,
      });
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_TO_NEW_EVENT_ITEM", { ...payload, id: response.data });
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message:
              "Etkinlik ekleme işleminiz başarıyla gerçekleştirildi." +
              payload.fillRezzToRezzarvation
                ? payload.emptyRezResponse
                  ? " Etkinliğiniz rezzervasyon sistemini de başarıyla eklendi."
                  : "Etkinliğiniz rezzervasyon sistemine eklenemedi. Lütfen kontrol edin."
                : "",
          },
        });
        return { ...payload, id: response.data };
      } else if (response.message === "FORBIDDEN") {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("forbidden"),
          },
        });
        return false;
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("insertIsNotSucceed"),
          },
        });
        return false;
      }
    },
    // event item sil
    async SEND_TO_REMOVE_EVENT_ITEM({ commit }, payload) {
      const response = await vm.$api.delete(`EventItems/${payload.id}`);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_TO_REMOVE_EVENT_ITEM", payload);
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("updatedIsSucceed"),
          },
        });
        return true;
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("updatedIsNotSucceed"),
          },
        });
        return false;
      }
    },
    // ürünleri getir
    async GET_EVENTS(
      { state, commit },
      { page = 1, search = "", limit = 12, type, categoryId = -1 }
    ) {
      if (
        state.events.length !== 0 &&
        (search === "") & state.eventListRequested &&
        state.events.map((event) => event.page).includes(page) &&
        categoryId === -1
      )
        return state.events; // daha önce liste çekilmişse return at.
      commit("SET_EVENTS_ARE_LOADING", true);
      const response = await vm.$api.get(
        `Events?page=${page}&searchKey=${search}&limit=${limit}${
          categoryId === -1 ? "" : `&categoryId=${categoryId}`
        }`
      );
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_EVENTS", {
          count: response.data.count || 0,
          search,
          categoryId,
          data: response.data.items.map((d) => {
            // .items gelecek.
            return {
              ...d,
              page,
              type,
            };
          }),
        });
        commit("SET_EVENTS_ARE_LOADING", false);
        return response.data;
      } else {
        commit("SET_EVENTS", []);
        commit("SET_EVENTS_ARE_LOADING", false);
        return [];
      }
    },
    // yeni ürünü kayıt etme
    async SEND_NEW_EVENT({ commit }, { fd, form }) {
      commit("SET_NEW_EVENT_IS_LOADING", true);
      commit("SET_EVENTS_ARE_LOADING", true);
      const response = await vm.$api.post(`Events`, fd);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_NEW_EVENT_TO_LIST", { ...form, id: response.data });
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("insertIsSucceed"),
          },
        });
        commit("SET_NEW_EVENT_IS_LOADING", false);
        commit("SET_EVENTS_ARE_LOADING", false);
        commit("CLEAR_CATEGORIES");
        return response.data;
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("insertIsNotSucceed"),
          },
        });
        commit("SET_NEW_EVENT_IS_LOADING", false);
        commit("SET_EVENTS_ARE_LOADING", false);
        return false;
      }
    },
    // ürünü güncelle
    async SEND_UPDATE_EVENT({ commit }, { fd, form }) {
      console.log(form, "sen d update event");
      commit("SET_NEW_EVENT_IS_LOADING", true); // store events.js
      commit("SET_EVENTS_ARE_LOADING", true); // store events.js
      const response = await vm.$api.put(`Events/${form.id}`, fd);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_UPDATE_EVENT_TO_LIST", { ...form }); // store events.js
        commit("SET_UPDATE_PRODUCT", { ...form }); // store products.js
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("updatedIsSucceed"),
          },
        });
        commit("SET_NEW_EVENT_IS_LOADING", false);
        commit("SET_EVENTS_ARE_LOADING", false);

        return form.id;
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("updatedIsNotSucceed"),
          },
        });
        commit("SET_NEW_EVENT_IS_LOADING", false);
        commit("SET_EVENTS_ARE_LOADING", false);
        return false;
      }
    },
    async SEND_USER_TO_EVENT(_, payload) {
      const response = await vm.$api.post("Products?table=event", payload);
      if (response.message === "OK") {
        return {
          status: true,
          variant: "success",
          message: "Ürün kişiye başarıyla eklendi.",
        };
      } else {
        return {
          status: true,
          variant: "danger",
          message: "Ürün kişiye eklenemedi. Lütfen daha sonra tekrar deneyin.",
        };
      }
    },
  },
};
